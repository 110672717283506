import { Icon } from "@components/Icons"
import PostModal from "@components/PostModal"
import { useDimensions } from "@hooks/use-dimensions"
import { useResize } from "@hooks/use-resize"
import { useStores } from "@hooks/use-stores"
import translate from "@src/i18n"
import { hoursDiff } from "@utils/date"
import { getWatermarkURL } from "@utils/path"
import { Avatar, Button, Divider, Image } from "antd"
import { Col, Row } from "antd"
import cx from "classnames"
import { Link } from "gatsby"
import _map from "lodash/map"
import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useMedia } from "react-use-media"
import Player from "video-react/lib/components/Player"

import Footer from "../PostCardAnonimatta/components/Footer"
import Comments from "../PostModal/components/Comments"
import MainCommentForm from "../PostModal/components/MainCommentForm"
import styles from "./style.module.less"

const ModelPost = (props) => {
  const {
    handleModelProfile,
    handleFollowers,
    handleUnfollowers,
    model,
    isFavorite,
    handleLikeUnlike,
    item,
  } = props

  const { authSite, post, feed, profile } = useStores()
  const { getCloudinaryWatermarkLink } = post
  const { user } = authSite
  const [visible, setVisible] = useState(false)
  const [imageWidth, setImageWidth] = useState("100%")
  const [showComments, setShowComments] = useState(false)
  const [likes, setLikes] = useState(item.totalLikes)
  const [liked, setLiked] = useState(item.liked)
  const [comments, setComments] = useState([])
  const [totalComments, setTotalComments] = useState(item.totalComments)
  const videoRef = useRef(null)
  const [imageWatermarkUrl, setImageWatermarkUrl] = useState(null)
  const videoWrapperRef = useRef()
  const imageRef = useRef(null)
  const [refDesktop] = useDimensions({
    timeout: 1000,
  })
  const [commentsHeight, setCommentsHeight] = useState("")

  const isMobile = useMedia({
    maxWidth: 1024,
  })
  // console.log(item)

  const {
    getPost,
    postLike,
    postDislike,
    postComment,
    postReplyComment,
    deleteComment,
    deleteReply,
    affiliated,
    datePublication,
    postNewFollow,
    deletedFollowers,
  } = feed

  const { getAffiliatedByUsername } = profile

  const [follow, setFollow] = useState(isFavorite ? true : false)
  const srcProfile = `${process.env.S3_IMAGES}/${item.urlProfile}`
  const srcPost = `${process.env.S3_IMAGES}/${item.urlImage}`

  const videoCover = `${process.env.S3_IMAGES}/${item.urlVideo}`
  const videoCoverUrl =
    videoCover.substr(0, videoCover.lastIndexOf(".")) + ".jpg"

  const srcImage = getWatermarkURL(item.urlImage, user)

  const getCloudinaryImageURL = async () => {
    const imageLink = await getCloudinaryWatermarkLink(srcImage)
    setImageWatermarkUrl(imageLink)
  }

  const calculatorTotal = (comments) => {
    let total = comments.reduce(
      (acc, cur) => acc + 1 + cur.replys.reduce((replyAcc) => replyAcc + 1, 0),
      0
    )
    return total
  }

  const getComments = async () => {
    const {
      data: [post],
    } = await getPost({ postId: item.id })

    if (post) {
      setComments(post.comments)
      setTotalComments(calculatorTotal(post.comments))
    }
  }

  useEffect(() => {
    async function fetchComments() {
      const affiliated = await getAffiliatedByUsername(item.affiliatedUsername)
      setFollow(affiliated.imFollowing)

      if (getPost) getComments()
    }
    fetchComments()
  }, [])

  const [screenWidth] = useResize()

  const locationFavorite = `${item.city}, ${item.state}`

  if (!item) return null

  const isOnlyText = !item.urlImage && !item.urlVideo

  const modalContentWrapperClasses = cx("flex", {
    "flex-row": !isOnlyText,
    "flex-column": isOnlyText,
  })
  const maxWidth = isOnlyText ? { maxWidth: "500px" } : {}

  const handleShowModal = () => {
    if (screenWidth < 768) {
      return
    }

    setVisible(true)
  }

  let height
  if (screenWidth <= 768) {
    height = screen.height - 168
    // console.log(height)
  }

  useEffect(() => {
    if (imageRef.current) {
      if (imageRef.current.naturalWidth > imageRef.current.naturalHeight) {
        setImageWidth("100%")
      } else {
        setImageWidth("auto")
      }
    }

    if (videoRef.current) {
      setCommentsHeight(videoRef.current.props.height)
    }
  }, [item.urlImage])

  const handleLikeButton = async (isLiked) => {
    if (isLiked) {
      const { data } = await postDislike({
        postId: item.id,
        affiliatedId: item.affiliatedId,
      })

      if (data.success) {
        setLiked(false)
        setLikes(data.likeCnt)
      }
    } else {
      const { data } = await postLike({
        postId: item.id,
        affiliatedId: item.affiliatedId,
      })

      if (data.success) {
        setLiked(true)
        setLikes(data.likeCnt)
      }
    }
    if (handleLikeUnlike) handleLikeUnlike()
  }

  const incrementTotalComments = () => {
    setTotalComments((prevState) => prevState + 1)
  }

  const onComment = async (values) => {
    const { textComment, commentId, user } = values

    if (!commentId) {
      const { data } = await postComment({
        postId: item.id,
        affiliatedId: item.affiliatedId,
        textComment,
      })

      setComments((prevState) => [
        {
          commentId: data.comment.id,
          textComment: data.comment.textComment,
          commentatorId: data.comment.commentatorId,
          commentatorName: user.name,
          commentatorUsername: user.username,
          commentatorNickname: user.nickname,
          commentatorPhoto: user.urlProfile || user.urlPhoto,
          replys: [],
        },
        ...prevState,
      ])
      incrementTotalComments()
      return
    }

    if (commentId) {
      const { data } = await postReplyComment({ commentId, textComment })

      setComments(
        _map(comments, (comment) => {
          if (comment.commentId === commentId) {
            return {
              ...comment,
              replys: [
                {
                  idReply: data.comment.id,
                  textComment: data.comment.textComment,
                  commentatorId: data.comment.commentatorId,
                  commentatorName: user.name,
                  commentatorUsername: user.username,
                  commentatorNickname: user.nickname,
                  commentatorPhoto: user.urlProfile || user.urlPhoto,
                },
                ...comment.replys,
              ],
            }
          }

          return comment
        })
      )
      incrementTotalComments()
      return
    }
  }

  const onDeleteComment = async ({ commentId }) => {
    const { data } = await deleteComment({ commentId })

    if (data.success) {
      setComments(comments.filter((comment) => comment.commentId !== commentId))
      setTotalComments(
        calculatorTotal(
          comments.filter((comment) => comment.commentId !== commentId)
        )
      )
    }
  }

  const onDeleteReply = async ({ commentId, replyId }) => {
    const { data } = await deleteReply({ replyId })

    if (data.success) {
      setComments(
        _map(comments, (comment) => {
          if (comment.commentId === commentId) {
            return {
              ...comment,
              replys: comment.replys.filter(
                (reply) => reply.idReply !== replyId
              ),
            }
          }

          return comment
        })
      )
    }
  }
  const sizeImage = (imageRef) => {
    setCommentsHeight(imageRef.current.height)
    setImageWidth(imageRef.current.width)
  }

  const handleFollowAndUnfollow = (id) => {
    follow ? handleUnfollow(id) : handleFollow(id)
    setFollow((prevState) => !prevState)
  }

  const handleFollow = async (id) => {
    await postNewFollow(id)
  }

  const handleUnfollow = async (id) => {
    console.log(item)
    await deletedFollowers(id)
  }

  return (
    <div className={cx(styles.singlePostContent)}>
      <Helmet>
        <title>{`Post #${item.id} de ${
          item.affiliatedNickname ? item.affiliatedNickname : item.affiliated
        } - Anonimatta`}</title>
      </Helmet>
      <div
        className={cx(styles.mediaSinglePost)}
        style={
          isMobile
            ? item.urlImage
              ? { height: "auto", backgroundColor: "transparent" }
              : { height: height, backgroundColor: "#000" }
            : item.urlImage
            ? { width: imageWidth, height: "" }
            : { backgroundColor: "#000" }
        }
      >
        {item.urlImage && (
          <img
            className={styles.media__img}
            src={`${process.env.S3_IMAGES}/${item.urlImage}`}
            alt={`Imagem do post do afiliado ${item.name}`}
            ref={imageRef}
            onLoad={() => sizeImage(imageRef)}
            style={isMobile ? { height: "100%" } : {}}
          />
        )}

        {item.urlVideo && (
          <video
            className={styles.media__video}
            controls
            controlsList="nodownload"
            poster={videoCoverUrl}
            preload="none"
            playsInline
          >
            <source
              src={`${process.env.S3_IMAGES}/${item.urlVideo}`}
              type="video/mp4"
            />
          </video>
        )}
      </div>

      <div className={cx("flex flex-column", styles.commentsSinglePost)}>
        <div
          className={cx(
            "bg-white flex flex-column justify-between",
            styles.headerCommentsSinglePost
          )}
        >
          <div className={cx(styles.likesAndDate, styles.pr25, styles.pl25)}>
            <Footer
              postId={item.id}
              affiliatedId={item.affiliatedId}
              likes={likes}
              liked={liked}
              totalComments={totalComments}
              comments={item.comments}
              handleLikeButton={() => handleLikeButton(liked)}
              handleCommentsButton={
                isMobile
                  ? () => setShowComments(!showComments)
                  : () => handleShowModal()
              }
            />

            <span className={cx(styles.dateSinglePost)}>
              {hoursDiff(item.datePublication, "pt-br", false)}
            </span>
          </div>
        </div>

        <div
          className={cx(
            "overflow-auto flex-grow-1",
            styles.post__comments__container
          )}
        >
          <div className={styles.post__container_header_description}>
            <div
              className={cx(
                "flex flex-row items-center",
                styles.postCreator,
                styles.pr25,
                styles.pl25
              )}
            >
              <div
                className={cx(
                  "flex fex-column items-center",
                  styles.post__profile
                )}
              >
                <img className={cx(styles.postCreatorImage)} src={srcProfile} />

                <Link>
                  <a
                    className={styles.authorNameComment}
                    href={`/perfil/${item.affiliatedUsername}`}
                  >
                    {item.affiliatedNickname
                      ? item.affiliatedNickname
                      : item.affiliated}
                  </a>
                </Link>
              </div>

              <a
                href="#"
                onClick={() => handleFollowAndUnfollow(item.affiliatedId)}
                className={`${styles.post__profile__follow} ${styles.followAndUnfollow}`}
              >
                {!follow ? "Seguir" : "Seguindo"}
              </a>
            </div>

            <p className={cx("ma0", styles.post__content)}>{item.postText}</p>
          </div>
          <Comments
            {...item}
            comments={comments}
            onFinish={onComment}
            onDeleteComment={onDeleteComment}
            onDeleteReply={onDeleteReply}
            formName="textComment"
          />
        </div>

        <div className={cx("comment_main_context", styles.mainFormSinglePost)}>
          <MainCommentForm
            placeholder="Comentar"
            formName="textComment"
            onFinish={onComment}
            size={35}
          />
        </div>
      </div>
    </div>
  )
}

export default ModelPost
