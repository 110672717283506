import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import { getParamsId } from "@utils/path"
import _map from "lodash/map"
import React, { useEffect, useState } from "react"
import { useMedia } from "react-use-media"

import ModelPost from "../../components/modelPost"

const PostPagePerfil = () => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState()
  const [isClient, setIsClient] = useState(false)
  const [postId, setPostId] = useState()
  const [item, setItem] = useState()
  const { post } = useStores()
  const isMobile = useMedia({
    maxWidth: 768,
  })

  const { findById } = post

  useEffect(() => {
    setIsClient(true)
    handleGetProfilePostView()
  }, [])

  const handleGetProfilePostView = async () => {
    const postId = getParamsId()

    const res = await findById(postId)
    setItem(res[0])
  }

  if (!isClient) return null

  return (
    <TranslateWrapper>
      <div className="page">
        {item && (
          <div
            className={isMobile ? "post_mobile" : "page__center"}
            style={isMobile ? { paddingBottom: "40px" } : {}}
          >
            <ModelPost item={item} />
          </div>
        )}
      </div>
    </TranslateWrapper>
  )
}

export default PostPagePerfil
